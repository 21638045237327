<template>
  <nav
    :class="
      btnFlag
        ? 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky'
        : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center'
    "
  >
    <div class="container">
      <router-link to="/Home">
        <b-img
          class="logo_img"
          :src="logo_img"
          fluid
          alt="Responsive image"
        ></b-img>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="openMenu"
      >
        <b-img
          class="logo_img2"
          :src="Multiplechoice"
          fluid
          alt="Responsive image"
        ></b-img>
      </button>
      <div
        :class="menu_show ? 'menu_show' : ''"
        class="collapse navbar-collapse"
        id="navbarCollapse"
        @click="close_menu"
      >
        <ul
          class="navbar-nav navbar-center"
          id="mySidenav"
          style="margin-left: auto"
        >
          <li class="nav-item menu_close">
            <img @click.stop="close_menu" :src="Close1" alt="" />
          </li>
          <li class="nav-item">
            <router-link to="/Home" active-class="activeClass"  >简介</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/About">关于我们</router-link>
            <!-- <ul class="nav-item-ul">
              <li>
                <router-link to="/About">企业简介</router-link>
              </li>
            </ul> -->
          </li>
          <li class="nav-item">
            <router-link to="/Join">加盟合作</router-link>
            <!-- <ul class="nav-item-ul">
              <li>
                <router-link to="/Join">代理优势</router-link>
              </li>
              <li>
                <router-link to="/Join#d3">代理加盟</router-link>
              </li>
            </ul> -->
          </li>
          <!-- <li class="nav-item">
            <router-link to="/experience">申请体验</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/anaLysis">行业分析</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/app">APP下载</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/applicationAgent">申请代理</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/contactUs">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import logo_img from "@/assets/images/logo.png";
import Multiplechoice from "@/assets/images/mobile/Multiplechoice.png";
import Close1 from "@/assets/images/mobile/Close1.png";

export default {
  name: "GlobalHeader",
  data() {
    return {
      logo_img,
      Close1,
      Multiplechoice,
      btnFlag: false,
      menu_show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    openMenu() {
      this.menu_show = !this.menu_show;
    },
    close_menu() {
      this.menu_show = false;
    },
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>

<style>
</style>
