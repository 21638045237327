<template>
  <div class="anaLysis">
    <section class="section" style="padding-bottom: 0">
      <crumbs title="申请代理" dsb="首页 > 申请代理" />
         <joinUs/>
    </section>
  </div>
</template>

<script>
import crumbs from "@/components/about/crumbs";
import joinUs from "@/components/Join/joinUs";

export default {
  name: "anaLysis",
  components: {
    crumbs,
    joinUs,
  },
  data() {
    return {
    };
  },
  
  mounted() {},
  methods: {
  },
};
</script>

<style scoped>

</style>