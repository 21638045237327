<template>
  <!-- 做刷卡机的几个理由 -->
  <div>
    <div class="supeRiority">
      <p class="title">做刷卡机的几个理由</p>
      <div class="container data-list">
        <div class="app-center">
          <img :src="Ring" alt="" />
          <ul class="app-left">
            <li>
              <p>时间自由</p>
              <img :src="Time" alt="" />
            </li>
            <li>
              <p>起步没压力没门槛</p>
              <img :src="Start" alt="" />
            </li>
            <li>
              <p>没有淡旺季 每天赚钱 被动收入</p>
              <img :src="Income" alt="" />
            </li>
            <li>
              <img :src="Markets1" alt="" />
              <p>市场刚需</p>
            </li>
            <li>
              <img :src="Pipes1" alt="" />
              <p>管道收入 持续增长</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Ring from "@/assets/images/Join/Ring.png";
import Time from "@/assets/images/Join/Time.png";
import Start from "@/assets/images/Join/Start.png";
import Income from "@/assets/images/Join/Income.png";
import Markets1 from "@/assets/images/Join/Markets1.png";
import Pipes1 from "@/assets/images/Join/Pipes1.png";
export default {
  name: "maChine",
  data() {
    return {
      Ring,
      Time,
      Start,
      Income,
      Markets1,
      Pipes1,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0;
  background-color: #f9f9f9;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }
  .data-list {
    display: flex;
    align-items: center;
    justify-content: center;
    .app-left {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      li {
        display: flex;
        align-items: center;
        line-height: 60px;
        img {
          width: 50px;
        }
      }
      li:nth-child(1) {
        position: absolute;
        top: 72px;
        left: 0;
        img {
          margin-left: 20px;
        }
      }
      li:nth-child(2) {
        position: absolute;
        top: 230px;
        left: -120px;
        img {
          margin-left: 20px;
        }
      }
      li:nth-child(3) {
        position: absolute;
        top: 385px;
        left: -95px;
        img {
          margin-left: 20px;
        }
      }
      li:nth-child(4) {
        position: absolute;
        top: 110px;
        right: -55px;
        img {
          margin-right: 20px;
        }
      }
      li:nth-child(5) {
        position: absolute;
        top: 350px;
        right: -100px;
        img {
          margin-right: 20px;
        }
      }
    }
    .app-center {
      width: 500px;
      text-align: center;
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
    width: 100%;
    padding: 40px 0 30px;
    background-color: #f9f9f9;
    .title {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 30px;
    }
    .data-list {
      display: flex;
      align-items: center;
      justify-content: center;
      .app-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        li {
          display: flex;
          align-items: center;
          line-height: 15px;
          font-size: 10px;
          img {
            width: 25px;
          }
          p {
            width: 55px;
          }
        }
        li:nth-child(1) {
          position: absolute;
          top: 40px;
          left: -25px;
          img {
            margin-left: 10px;
          }
        }
        li:nth-child(2) {
          position: absolute;
          top: 120px;
          left: -53px;
          img {
            margin-left: 10px;
          }
        }
        li:nth-child(3) {
          position: absolute;
          top: 178px;
          left: 1px;
          img {
            margin-left: 10px;
          }
        }
        li:nth-child(4) {
          position: absolute;
          top: 58px;
          left: 212px;
          img {
            margin-right: 10px;
          }
        }
        li:nth-child(5) {
          position: absolute;
          top: 177px;
          right: -36px;
          img {
            margin-right: 10px;
          }
        }
      }
      .app-center {
        width: 250px;
        text-align: center;
        position: relative;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
