<template>
  <!-- 软件优势 -->
  <div>
    <div class="supeRiority">
      <p class="title">软件优势</p>
      <div class="container">
        <ul class="data-list row">
          <li class="col-6 col-md-3 col-sm-6">
            <p>数据支撑</p>
            <img :src="Data" alt="" />
            <p>数据支撑商户展业，实现数据化的指 导</p>
          </li>
          <li class="col-6 col-md-3 col-sm-6">
            <p>产品扶持</p>
            <img :src="Products" alt="" />
            <p>成熟的产品推广能力，拥有丰富的运 作经验</p>
          </li>
          <li class="col-6 col-md-3 col-sm-6">
            <p>售后实力</p>
            <img :src="Aftersale" alt="" />
            <p>完善的售后服务流程体系，精细的分 工协作运营</p>
          </li>
          <li class="col-6 col-md-3 col-sm-6">
            <p>技术优势</p>
            <img :src="Technology" alt="" />
            <p>核心人才为专业的技术研发人员，拥 有具有核心竞争力的研发团队</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Data from "@/assets/images/profile/Data.png";
import Technology from "@/assets/images/profile/Technology.png";
import Aftersale from "@/assets/images/profile/Aftersale.png";
import Products from "@/assets/images/profile/Products.png";
export default {
  name: "softWare",
  data() {
    return {
      Data,
      Products,
      Aftersale,
      Technology,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0;
  background-color: #f9f9f9;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 100px;
  }
  .data-list {
    li {
      :nth-child(1) {
        width: 100%;
        height: 80px;
        line-height: 80px;
        padding: 0 20px;
        font-size: 32px;
        color: #333333;
        background-color: #fff;
      }
      :nth-child(2) {
        width: 100%;
      }
      :nth-child(3) {
        font-size: 14px;
        line-height: 21px;
        color: #999999;
        background-color: #fff;
        padding: 36px 20px 64px;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
    width: 100%;
    padding: 40px 0 10px;
    background-color: #f9f9f9;
    .title {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 30px;
    }
    .data-list {
      li {
        margin-bottom: 20px;
        :nth-child(1) {
          width: 100%;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          font-size: 16px;
          color: #333333;
          text-align: center;
          background-color: #fff;
        }
        :nth-child(2) {
          width: 100%;
        }
        :nth-child(3) {
          font-size: 7px;
          line-height: 18px;
          color: #999999;
          background-color: #fff;
          padding: 20px 10px 25px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
