<template>
  <div>
    <section class="section" style="padding-bottom: 0">
      <crumbs/>
      <aboutInfo />
      <softDetail/>
      <softWare />
    </section>
  </div>
</template>

<script>
import crumbs from "@/components/about/crumbs";
import softDetail from "@/components/about/softDetail";
import aboutInfo from "@/components/home/aboutInfo";
import softWare from "@/components/home/softWare";
export default {
  name: "About",
  components: {
    crumbs,
    softDetail,
    aboutInfo,
    softWare,
  },
  data() {
    return {
      height: "100%",
    };
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="less" scoped>

</style>
