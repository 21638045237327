var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"supeRiority"},[_c('p',{staticClass:"title"},[_vm._v("联系我们")]),_c('div',{staticClass:"container"},[_c('ul',{staticClass:"data-list"},[_c('li',{staticClass:"app-left"},[_vm._m(0),_c('div',{staticClass:"open-phone"},[_c('div',[_c('img',{attrs:{"src":_vm.Telephone,"alt":""}}),_c('p',[_vm._v("客服热线")])]),_c('p',[_vm._v("4008090573")])]),_c('div',{staticClass:"open-phone"},[_c('div',[_c('img',{attrs:{"src":_vm.Address,"alt":""}}),_c('p',[_vm._v("总部地址")])]),_c('p',[_vm._v("浙江省嘉兴市经济技术开发区中环南路2608号福地广场")])])]),_c('li',{staticClass:"app-right"},[_c('baidu-map',{staticClass:"map",attrs:{"zoom":_vm.zoom,"center":{ lng: _vm.marker.lng, lat: _vm.marker.lat },"scroll-wheel-zoom":true}},[_c('bm-marker',{attrs:{"position":{ lng: _vm.marker.lng, lat: _vm.marker.lat },"dragging":true}},[_c('bm-label',{attrs:{"content":"浙江省嘉兴市经济技术开发区中环南路2608号福地广场","labelStyle":{
                  color: '#666',
                  fontSize: '14px',
                  border: '1px solid #999',
                },"offset":{ width: -35, height: 30 }}})],1)],1),_vm._m(1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kefu"},[_c('p',[_vm._v("在线客服")]),_c('p',[_vm._v("工作时间 9:00-21:00，您可以通过电话联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-botton"},[_c('div',{staticClass:"kefu"},[_c('p',[_vm._v("在线客服")]),_c('p',[_vm._v("工作时间 9:00-21:00，您可以通过电话联系我们")])]),_c('div',{staticClass:"kefu"},[_c('p',[_vm._v("客服热线")]),_c('p',[_vm._v("4008090573")])]),_c('div',{staticClass:"kefu"},[_c('p',[_vm._v("总部地址")]),_c('p',[_vm._v("浙江省嘉兴市经济技术开发区中环南路2608号福地广场")])])])
}]

export { render, staticRenderFns }