<template>
  <!-- 联系我们 -->
  <div>
    <div class="supeRiority">
      <p class="title">联系我们</p>
      <div class="container">
        <ul class="data-list">
          <li class="app-left">
            <div class="kefu">
              <p>在线客服</p>
              <p>工作时间 9:00-21:00，您可以通过电话联系我们</p>
            </div>
            <div class="open-phone">
              <div>
                <img :src="Telephone" alt="" />
                <p>客服热线</p>
              </div>
              <p>4008090573</p>
            </div>
            <div class="open-phone">
              <div>
                <img :src="Address" alt="" />
                <p>总部地址</p>
              </div>
              <p>浙江省嘉兴市经济技术开发区中环南路2608号福地广场</p>
            </div>
          </li>
          <li class="app-right">
            <baidu-map
              :zoom="zoom"
              :center="{ lng: marker.lng, lat: marker.lat }"
              class="map"
              :scroll-wheel-zoom="true"
            >
              <bm-marker
                :position="{ lng: marker.lng, lat: marker.lat }"
                :dragging="true"
              >
                <bm-label
                  content="浙江省嘉兴市经济技术开发区中环南路2608号福地广场"
                  :labelStyle="{
                    color: '#666',
                    fontSize: '14px',
                    border: '1px solid #999',
                  }"
                  :offset="{ width: -35, height: 30 }"
                />
              </bm-marker>
            </baidu-map>
            <div class="app-botton">
              <div class="kefu">
                <p>在线客服</p>
                <p>工作时间 9:00-21:00，您可以通过电话联系我们</p>
              </div>
              <div class="kefu">
                <p>客服热线</p>
                <p>4008090573</p>
              </div>
              <div class="kefu">
                <p>总部地址</p>
                <p>浙江省嘉兴市经济技术开发区中环南路2608号福地广场</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Address from "@/assets/images/profile/Address.png";
import Telephone from "@/assets/images/profile/Telephone.png";

export default {
  name: "contactUs",
  data() {
    return {
      Address,
      Telephone,
      zoom: 16,
      marker: {
        lng: "120.733",
        lat: "30.747",
      },
    };
  },
  mounted() {},

  methods: {
    lookDetail() {},
    handler({ BMap, map }) {},
  },
};
</script>

<style lang="less" scoped>
.map {
  width: 100%;
  height: 300px;
}
.supeRiority {
  width: 100%;
  padding: 100px 0 90px;
  background-color: #f9f9f9;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }
  .data-list {
    display: flex;
    .app-left {
      width: 40%;
      padding: 0 50px 0 20px;
      .kefu {
        margin-bottom: 60px;
        p:nth-child(1) {
          font-size: 24px;
          color: #333333;
          margin-bottom: 15px;
        }
        p:nth-child(2) {
          font-size: 16px;
          color: #999999;
        }
      }
      .open-phone {
        display: flex;
        align-items: center;
        line-height: 26px;
        font-size: 18px;
        color: #666666;
        margin-bottom: 40px;
        img {
          width: 50px;
          display: block;
          margin-right: 20px;
        }
        > div {
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }
        > P {
          margin-left: 25px;
          flex: 1;
        }
      }
    }
    .app-right {
      width: 60%;
      .app-botton {
        display: none;
      }
    }
  }
}
@media (max-width: 750px) {
  .map {
    width: 100%;
    height: 160px;
  }
  .supeRiority {
    width: 100%;
    padding: 40px 0 0px;
    background-color: #f9f9f9;
    .title {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 20px;
    }
    .data-list {
      display: flex;
      .app-left {
        display: none;
        width: 40%;
        padding: 0 50px 0 20px;
        .kefu {
          margin-bottom: 60px;
          p:nth-child(1) {
            font-size: 24px;
            color: #333333;
            margin-bottom: 15px;
          }
          p:nth-child(2) {
            font-size: 16px;
            color: #999999;
          }
        }
        .open-phone {
          display: flex;
          align-items: center;
          line-height: 26px;
          font-size: 18px;
          color: #666666;
          margin-bottom: 40px;
          img {
            width: 50px;
            display: block;
            margin-right: 20px;
          }
          > div {
            display: flex;
            align-items: center;
            flex-shrink: 0;
          }
          > P {
            margin-left: 25px;
            flex: 1;
          }
        }
      }
      .app-right {
        width: 100%;
        .app-botton {
          margin-top: 30px;
          margin-bottom: 30px;
          display: block;
          .kefu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 9px;
            margin-bottom: 10px;
            line-height: 20px;
            color: #666;
            p:nth-child(1) {
              width: 25%;
            }
          }
        }
      }
    }
  }
}
</style>
