<template>
  <section class="footer">
    <div class="container list-unstyled">
      <div class="">
        <ul class="footer-sub-menu">
          <li><a href="#/Home" class="footer-link">简介</a></li>
          <li><a href="#/About" class="footer-link">关于我们</a></li>
          <li><a href="#/Join" class="footer-link">加盟合作</a></li>
          <!-- <li><a href="#" class="footer-link">申请体验</a></li> -->
          <li> <a href="#/anaLysis" class="footer-link">行业分析</a></li>
          <li><a href="#/app" class="footer-link"> APP下载</a></li>
          <!-- <li><a href="#" class="footer-link"> 代理加盟</a></li> -->
          <li><a href="#/contactUs" class="footer-link"> 联系我们</a></li>
        </ul>
      </div>
      <div class="">
        <h4 class="f-18 font-weight-normal text-white mb-20">工作时间</h4>
        <ul class="footer-sub-menu">
          <li>周一至周五</li>
          <li>9:00 - 21:00</li>
        </ul>
      </div>
      <div class="">
        <h4 class="f-18 font-weight-normal text-white mb-20">联系信息</h4>
        <ul class="footer-sub-menu">
          <li>电话：4008090573</li>
          <li>
            
              地址：浙江省嘉兴市经济技术开发区中环南路2608号福地广场
            
          </li>
        </ul>
      </div>
      <div class="">
        <ul class="list-ermma footer-sub-menu row">
          <li class="col-4">
            <b-img
              class="img_erma"
              :src="video_erma"
              fluid
              alt="Responsive image"
            ></b-img>
            <p>视频号</p>
          </li>
          <li class="col-4">
            <b-img
              class="img_erma"
              :src="weixin_erma"
              fluid
              alt="Responsive image"
            ></b-img>
            <p>公众号</p>
          </li>
          <li class="col-4">
            <b-img
              class="img_erma"
              :src="kefu_erma"
              fluid
              alt="Responsive image"
            ></b-img>
            <p>微信客服</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="app_show">
        <div class="app-botton">
          <div class="kefu">
            <p>工作时间</p>
            <p>周一至周五 9:00-21:00</p>
          </div>
          <div class="kefu">
            <p>联系电话</p>
            <p>4008090573</p>
          </div>
          <div class="kefu">
            <p>地址</p>
            <p>浙江省嘉兴市经济技术开发区中环南路2608号福地广场</p>
          </div>
        </div>
        <div class="app-erma">
          <div>
            <p @click.stop="open_saoma(1)">视频号</p>
          </div>
          <div>
            <p  @click.stop="open_saoma(2)">公众号</p>
          </div>
          <div>
            <p  @click.stop="open_saoma(3)">微信客服</p>
          </div>
        </div>
      </div>

      <div class="band-all">
        <p class="f-12">嘉兴尚东 © 2011-2023 版权所有</p>
        <p class="f-12">
          <span
            ><a
              href="https://beian.miit.gov.cn/#/Integrated/index"
              style="color: #fff"
              >浙ICP备19027797号-1</a
            ></span
          >

          <!-- <span>
            <b-img
              style="width: 16px; margin-bottom: 3px"
              :src="beian_img"
              fluid
              alt="Responsive image"
            ></b-img>
            粤公网安备 44030502001837号</span
          > -->
          <!-- <span>增值电信业务经营许可证：粤B2-20192653</span> -->
        </p>
      </div>
      <div class="toast_erma" :class="open_erma?'open_erma':''">
        <div class="toast_box">
          <img :src="video_erma" class="kefu_erma" v-if="open_index == 1" alt="" />
           <img :src="weixin_erma" class="kefu_erma" v-else-if="open_index == 2" alt="" />
          <img :src="kefu_erma" class="kefu_erma" v-else-if="open_index == 3" alt="" />
          <img :src="Close" class="Close" @click.stop="close_toast"  alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import video_erma from "@/assets/images/mobile/Videonumber.png";
import weixin_erma from "@/assets/images/mobile/Publicnumber.png";
import kefu_erma from "@/assets/images/mobile/Customerservice.png";
import beian_img from "@/assets/images/mobile/beian_img.png";
import Close from "@/assets/images/mobile/Close.png";
export default {
  name: "GlobalFooter",
  data() {
    return {
      video_erma,
      weixin_erma,
      kefu_erma,
      beian_img,
      Close,
      open_erma:false,
      open_index:1
    };
  },
  methods:{
    open_saoma(value){
      this.open_index = value;
      this.open_erma=true;
    },
    close_toast(){
      this.open_erma=false;
    }
  }
};
</script>

<style lang="less" scoped>
</style>
