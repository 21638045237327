<template>
  <div>
    <div class="about-info">
      <div class="title">加盟流程</div>
      <div class="">
        <ul class="info-list">
          <li class="">
            <div class="img-box">
              <div class="first-line"></div>
              <img :src="Application" alt="" />
              <div></div>
            </div>
            <p>提交加盟申请</p>
          </li>
          <li class="">
            <div class="img-box">
              <div></div>
              <img :src="Coperation" alt="" />
              <div></div>
            </div>
            <p>合作浅谈</p>
          </li>
          <li class="">
            <div class="img-box">
              <div></div>
              <img :src="Agreement" alt="" />
              <div></div>
            </div>
            <p>签订合作协议</p>
          </li>
          <li class="">
            <div class="img-box">
              <div></div>
              <img :src="Authorisation" alt="" />
              <div></div>
            </div>
            <p>服务商授权</p>
          </li>
          <li class="">
            <div class="img-box">
              <div></div>
              <img :src="Success" alt="" />
              <div class="first-line"></div>
            </div>
            <p>加盟成功</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Application from "@/assets/images/Join/Application.png";
import Coperation from "@/assets/images/Join/Co-operation.png";
import Agreement from "@/assets/images/Join/Agreement.png";
import Authorisation from "@/assets/images/Join/Authorisation.png";
import Success from "@/assets/images/Join/Success.png";
export default {
  name: "joinProcess",
  data() {
    return {
      Application,
      Coperation,
      Agreement,
      Authorisation,
      Success,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.about-info {
  padding: 100px 0;
  background-image: url("../../assets/images/Join/Background1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title {
    text-align: center;
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 100px;
  }
  .info-list {
    width: 100%;
    display: flex;
    > li {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img-box {
        width: 100%;
        display: flex;
        align-items: center;
        > div {
          width: 50%;
          height: 1px;
          background-color: #fff;
        }
        .first-line {
          visibility: hidden;
        }
      }
      img {
        width: 80px;
        display: block;
      }
      p {
        margin-top: 20px;
        text-align: center;
        font-size: 20px;
        color: #ffffff;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .about-info {
    padding: 40px 0;
    background-image: url("../../assets/images/Join/Background1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .title {
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 50px;
    }
    .info-list {
      width: 100%;
      display: flex;
      > li {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img-box {
          width: 100%;
          display: flex;
          align-items: center;
          > div {
            width: 50%;
            height: 1px;
            background-color: #fff;
          }
          .first-line {
            visibility: visible;
          }
        }
        img {
          width: 40px;
          display: block;
        }
        p {
          margin-top: 10px;
          text-align: center;
          font-size: 10px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
