<template>
  <div class="anaLysis">
    <section class="section" style="padding-bottom: 0">
      <crumbs title="APP下载" dsb="首页 > APP下载" />
       <downLoad />
    </section>
  </div>
</template>

<script>
import crumbs from "@/components/about/crumbs";
import downLoad from "@/components/home/downLoad";

export default {
  name: "anaLysis",
  components: {
    crumbs,
    downLoad,
  },
  data() {
    return {
    };
  },
  
  mounted() {},
  methods: {
  },
};
</script>

<style scoped>

</style>