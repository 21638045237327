<template>
  <div class="anaLysis">
    <section class="section" style="padding-bottom: 0">
      <crumbs title="行业分析" dsb="首页 > 行业分析" />
      <inAnalysis />
      <div class="pagination-box">
        <div class="container">
          <pagination
            :page="pageData.page"
            :page-size="pageData.pageSize"
            :total="total"
            :on-page-change="onPage"
            :showSizes="true"
            :pageSizeList="pageData.pageSizeList"
            :on-page-size-change="onSize"
            class="pagi page-content"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import crumbs from "@/components/about/crumbs";
import inAnalysis from "@/components/analysis/analysis";
import pagination from "@/components/analysis/pagInation.vue";
export default {
  name: "anaLysis",
  components: {
    crumbs,
    inAnalysis,
    pagination,
  },
  data() {
    return {
      pageData: {
        pageTotal: 4,
        page: 1,
        pageSize: 10,
        pageSizeList: [10, 20, 30],
      },
    };
  },
  computed: {
    total() {
      return this.pageData.pageTotal || 0;
    },
  },
  mounted() {},
  methods: {
    // 分页
    onPage(pageNow) {
      console.log("pageNow", pageNow);
      this.pageData.page = pageNow;
    },
    onSize(e) {
      this.pageData.pageSize = e;
    },
  },
};
</script>

<style scoped>
.pagination-box {
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
}
</style>