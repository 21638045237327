<template>
  <div :class="wrapper">
    <template>
      <div :class="addressHeader">
        <ul>
          <li :class="{ active: showProvinceTab === true }">
            <div @click.stop="resetProvince">
              <p>
                {{
                  currentProvince && !staticPlaceholder
                    ? currentProvince
                    : placeholders.province
                }}
              </p>
              <img :src="PullDown" alt="" />
            </div>
            <ul
              class="address-container innerbox"
              v-if="showProvinceTab === true && !clickRootNum"
            >
              <li
                v-for="(item, index) in provinces"
                :class="{ active: item === currentProvince }"
                @click="chooseProvince(item)"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </li>
          <template>
            <li :class="{ active: showCityTab === true }">
              <div @click.stop="resetCity">
                <p>
                  {{
                    currentCity && !staticPlaceholder
                      ? currentCity
                      : placeholders.city
                  }}
                </p>
                <img :src="PullDown" alt="" />
              </div>
              <ul
                class="address-container innerbox"
                v-if="showCityTab === true && !clickRootNum"
              >
                <li
                  v-for="(item, index) in cities"
                  :class="{ active: item === currentCity }"
                  @click="chooseCity(item)"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </li>
            <li :class="{ active: showAreaTab === true }">
              <div @click.stop="resetArea">
                <p>
                  {{
                    currentArea && !staticPlaceholder
                      ? currentArea
                      : placeholders.area
                  }}
                </p>
                <img :src="PullDown" alt="" />
              </div>
              <ul
                class="address-container innerbox"
                v-if="showAreaTab === true && !clickRootNum"
              >
                <li
                  v-for="(item, index) in areas"
                  :class="{ active: item === currentArea }"
                  @click="chooseArea(item)"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import { isChn, isEmpty, isDistCode } from "./util";
import { autoCompleteDistCode } from "./transform";
import DEFAULT_PROVINCE from "./province";
import DEFAULT_CITY from "./city";
import DEFAULT_AREA from "./area";
import PullDown from "@/assets/images/Join/PullDown.png";
export default {
  name: "v-distpicker",
  props: {
    province: { type: [String, Number], default: "" },
    city: { type: [String, Number], default: "" },
    area: { type: [String, Number], default: "" },
    hideArea: { type: Boolean, default: false },
    onlyProvince: { type: Boolean, default: false },
    staticPlaceholder: { type: Boolean, default: false },
    placeholders: {
      type: Object,
      default() {
        return {
          province: "省",
          city: "市",
          area: "区",
        };
      },
    },
    provinceSource: Object,
    citySource: Object,
    areaSource: Object,
    disabled: { type: Boolean, default: false },
    provinceDisabled: { type: Boolean, default: false },
    cityDisabled: { type: Boolean, default: false },
    areaDisabled: { type: Boolean, default: false },
    addressHeader: { type: String, default: "address-header" },
    wrapper: { type: String, default: "distpicker-address-wrapper" },
  },
  data() {
    return {
      tab: 1,
      showProvinceTab: false,
      showCityTab: false,
      showAreaTab: false,
      provinces: [],
      cities: [],
      areas: [],
      provinceData: {},
      cityData: {},
      areaData: {},
      currentProvince: "",
      currentCity: "",
      currentArea: "",
      PullDown,
    };
  },
  computed: {
    clickRootNum: {
      get() {
        return this.$store.state.clickRootNum;
      },
    },
  },
  created() {
    this.provinceData = this.provinceSource || DEFAULT_PROVINCE;
    this.cityData = this.citySource || DEFAULT_CITY;
    this.areaData = this.areaSource || DEFAULT_AREA;
    let provinceVal = this.province;
    let cityVal = this.city;
    if (isDistCode(this.area)) {
      let { provinceCode, cityCode } = autoCompleteDistCode(this.area, "area");
      this.currentProvince =
        this.getProvinceVal(provinceCode, false) || this.placeholders.province;
      provinceVal = provinceCode;
      this.currentCity =
        this.getCityVal(cityCode, false) || this.placeholders.city;
      cityVal = cityCode;
    }
    let { name: areaName } = this.getCodeValue(this.area, "area");
    this.currentArea = areaName || this.placeholders.area;
    if (isEmpty(this.currentProvince)) {
      let { name: provinceName } = this.getCodeValue(provinceVal, "province");
      this.currentProvince = provinceName || this.placeholders.province;
    }
    if (isEmpty(this.currentCity)) {
      let { name: cityName } = this.getCodeValue(cityVal, "city");
      this.currentCity = cityName || this.placeholders.city;
    }
  },
  watch: {
    currentProvince(value) {
      this.$emit("change-province", this.setData(value, "province"));
      if (this.onlyProvince) {
        this.emit("change");
      }
    },
    currentCity(value) {
      this.$emit("change-city", this.setData(value, "city"));
      if (this.hideArea) {
        this.emit("change");
      }
    },
    currentArea(value) {
      this.$emit("change-area", this.setData(value, "area"));
      this.emit("change");
    },
    province(value) {
      let val = this.province || this.placeholders.province;
      let { name: provinceName, code: provinceCode } = this.getCodeValue(
        val,
        "province"
      );
      this.currentProvince = provinceName;
      this.cities = this.getCityList(provinceCode);
    },
    city(value) {
      let city = this.city || this.placeholders.city;
      let { name: cityName, code: cityCode } = this.getCodeValue(
        city,
        "city",
        this.cities
      );
      this.currentCity = cityName;
      this.areas = this.getAreaList(cityCode);
    },
    area(value) {
      let area = this.area || this.placeholders.area;
      this.changeAreaCode(area);
    },
  },
  methods: {
    setData(value, type) {
      let code = "";
      if (!isEmpty(value)) {
        switch (type) {
          case "area":
            let isAreaHolder = this.currentArea == this.placeholders.area;
            code = isAreaHolder ? "" : this.getAreaVal(value, true, this.areas);
            break;
          case "city":
            let isCityHolder = value == this.placeholders.city;
            code = isCityHolder
              ? ""
              : this.getCityVal(value, true, this.cities);
            break;
          case "province":
            let isProvinceHolder = value == this.placeholders.province;
            code = isProvinceHolder ? "" : this.getProvinceVal(value, true);
            break;
        }
      }
      return { code, value };
    },
    emit(name) {
      let data = {
        province: this.setData(this.currentProvince, "province"),
      };
      if (!this.onlyProvince) {
        this.$set(data, "city", this.setData(this.currentCity, "city"));
      }
      if (!this.onlyProvince && !this.hideArea) {
        this.$set(data, "area", this.setData(this.currentArea, "area"));
      }
      this.$emit(name, data);
    },
    changeArea() {
      this.$emit("area", this.setData(this.currentArea, "area"));
      this.emit("selected");
    },
    getCities() {
      this.cities = this.getCityList(this.currentProvince);
      this.$emit("province", this.setData(this.currentProvince, "province"));
      if (this.onlyProvince) {
        this.emit("selected");
      }
      this.cleanList();
    },
    getAreas() {
      this.currentArea = this.placeholders.area;
      let { code: cityCode } = this.getCodeValue(
        this.currentCity,
        "city",
        this.cities
      );
      this.areas = this.getAreaList(cityCode);
      this.$emit("city", this.setData(this.currentCity, "city"));
      if (this.hideArea) {
        this.emit("selected");
      }
    },
    resetProvince() {
      this.$store.commit("clickRootNumAction", 0);
      this.showProvinceTab = !this.showProvinceTab;
      this.provinces = this.getProvinceList();
      this.showCityTab = false;
      this.showAreaTab = false;
    },
    resetCity() {
      this.$store.commit("clickRootNumAction", 0);
      this.showCityTab = !this.showCityTab;
      this.showProvinceTab = false;
      this.showAreaTab = false;
      this.cities = this.getCityList(this.currentProvince);
    },
    resetArea() {
      this.$store.commit("clickRootNumAction", 0);
      this.showAreaTab = !this.showAreaTab;
      this.showProvinceTab = false;
      this.showCityTab = false;
      this.areas = this.getAreaList(this.currentCity);
    },
    chooseProvince(name) {
      this.showProvinceTab = !this.showProvinceTab;
      if (name === this.currentProvince) {
        return;
      } else {
        this.currentProvince = name;
        this.currentCity = this.placeholders.city;
        this.currentArea = this.placeholders.area;
        this.getCities();
      }
    },
    chooseCity(name) {
      if (name === this.currentCity) {
        this.showCityTab = !this.showCityTab;
        return;
      } else {
        this.currentCity = name;
        this.currentArea = this.placeholders.area;
        this.showCityTab = false;
        this.getAreas();
      }
    },
    chooseArea(name) {
      this.currentArea = name;
      this.showAreaTab = !this.showAreaTab;
      this.changeArea();
    },
    cleanList() {
      this.areas = [];
    },

    changeAreaCode(areaCode) {
      if (isDistCode(areaCode)) {
        let { provinceCode, cityCode } = autoCompleteDistCode(areaCode, "area");
        this.currentProvince = this.getProvinceVal(provinceCode, false);
        this.currentCity = this.getCityVal(cityCode, false);
        this.cities = this.getCityList(provinceCode);
        this.areas = this.getAreaList(cityCode);
      }
      let { name: areaName } = this.getCodeValue(areaCode, "area");
      this.currentArea = areaName;
    },
    /**
     * 根据名称 或 编码 ,返回下拉框选项
     * 名称转换为编码查找
     *@param codeOrName 名称 或 编码
     *
     */
    getCityList(codeOrName) {
      let code = isChn(codeOrName)
        ? this.getProvinceVal(codeOrName, true)
        : codeOrName;
      return this.cityData[code] || ["选择市"];
    },

    getAreaList(codeOrName) {
      let code = isChn(codeOrName)
        ? this.getCityVal(codeOrName, true)
        : codeOrName;
      return this.areaData[code] || ["选择区"];
    },

    getProvinceList() {
      return this.provinceData;
    },

    getTransformData(val, itemCode, data, isName) {
      if (isName) {
        if (data[itemCode] == val) return itemCode;
      } else {
        if (itemCode == val) return data[itemCode];
      }
      return null;
    },

    /**
     * 查找
     * @param provinceVal 值
     * @param data 数据源,默认从所有数据查找
     * @param isName false 传入编码 返回 名称，true 传入名称 返回 编码
     */
    getCityVal(cityVal, isName = false, data) {
      if (data) {
        for (let itemCode in data) {
          let val = this.getTransformData(cityVal, itemCode, data, isName);
          if (val) return val;
        }
      } else {
        let cityArr = Object.values(this.cityData);
        for (let item of cityArr) {
          for (let itemCode in item) {
            let val = this.getTransformData(cityVal, itemCode, item, isName);
            if (val) return val;
          }
        }
      }
      return cityVal;
    },

    getProvinceVal(provinceVal, isName = false) {
      for (let itemCode in this.provinceData) {
        let val = this.getTransformData(
          provinceVal,
          itemCode,
          this.provinceData,
          isName
        );
        if (val) return val;
      }
      return provinceVal;
    },

    getAreaVal(areaVal, isName = false, data) {
      if (data) {
        for (let itemCode in data) {
          let val = this.getTransformData(areaVal, itemCode, data, isName);
          if (val) return val;
        }
      } else {
        let areaArr = Object.values(this.areaData);
        for (let item of areaArr) {
          for (let itemCode in item) {
            let val = this.getTransformData(areaVal, itemCode, item, isName);
            if (val) return val;
          }
        }
      }
      return areaVal;
    },

    /**
     * 查找
     */
    getCodeValue(codeOrName, type, data) {
      if (isEmpty(codeOrName)) return { name: "", value: "" };
      let value = "";
      switch (type) {
        case "area":
          value = this.getAreaVal(codeOrName, isChn(codeOrName), data);
          break;
        case "city":
          value = this.getCityVal(codeOrName, isChn(codeOrName), data);
          break;
        case "province":
          value = this.getProvinceVal(codeOrName, isChn(codeOrName));
          break;
      }
      return isChn(codeOrName)
        ? { name: codeOrName, code: value }
        : { name: value, code: codeOrName };
    },
  },
};
</script>

<style lang="less" scoped>
.distpicker-address-wrapper {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
  .address-header {
    background-color: #fff;
    > ul {
      display: flex;
      align-items: center;
      > li {
        position: relative;
        width: 30%;
        margin-right: 5%;
        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
          line-height: 60px;
          background-color: #ffffff;
          border-radius: 20px;
          border: solid 1px #cccccc;
          text-align: center;
          p {
            width: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          img {
            width: 18px;
            margin-left: 6px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .address-container {
    position: absolute;
    width: 100%;
    top: 70px;
    left: 0;
    z-index:2;
    background-color: #f9f9f9;
    border-radius: 10px;
    max-height: 300px;
    overflow: auto;
    li {
      padding: 8px 10px;
      line-height: 25px;
      &.active {
        color: #2880ff;
      }
    }
  }
}
.disabled-color {
  background: #f8f8f8;
}
// 滚动条整体样式
.innerbox::-webkit-scrollbar {
  width: 4px;
}
// 滚动条内嵌滑块
.innerbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}
// 滚动条边角
.innerbox::-webkit-scrollbar-corner {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}
/* 手机端样式 */
@media (max-width: 750px) {
  .distpicker-address-wrapper {
    .address-header {
      background-color: #fff;
      > ul {
        display: flex;
        align-items: center;
        > li {
          position: relative;
          width: 30%;
          margin-right: 5%;
          font-size: 10px;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            line-height: 30px;
            background-color: #ffffff;
            border-radius: 10px;
            border: solid 1px #cccccc;
            text-align: center;
            p {
              width: 50px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            img {
              width: 10px;
              margin-left: 4px;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .address-container {
      position: absolute;
      width: 100%;
      top: 40px;
      left: 0;
      background-color: #f9f9f9;
      border-radius: 10px;
      max-height: 200px;
      overflow: auto;
      font-size: 10px;
      li {
        padding: 8px 10px;
        line-height: 20px;
        text-align: center;
        &.active {
          color: #2880ff;
        }
      }
    }
  }
}
</style>