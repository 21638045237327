<template>
  <!-- 软件详情 -->
  <div>
    <div class="supeRiority">
      <p class="title">软件详情</p>
      <p class="dsb container">
        联创伙伴APP是一款多产品融合的综合展业平台使用方便安全，有利于用户直观地对业绩的掌控对商户的管理。
      </p>
      <div class="container">
        <ul class="data-list row">
          <li class="col-4">
            <img :src="Home" alt="" />
            <div class="biaoji"></div>
            <p>数据支撑</p>
            <p>新功能指尖全掌握</p>
          </li>
          <li class="col-4">
            <img :src="Performance" alt="" />
            <div class="biaoji"></div>
            <p>数据可视化</p>
            <p>交易数据清晰直观</p>
          </li>
          <li class="col-4">
            <img :src="Mine" alt="" />
            <div class="biaoji"></div>
            <p>操作简单</p>
            <p>操作简单提高效率</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Home from "@/assets/images/Home.png";
import Performance from "@/assets/images/Performance.png";
import Mine from "@/assets/images/Mine.png";
export default {
  name: "softDetail",
  data() {
    return {
      Home,
      Performance,
      Mine,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0;
  background-color: #fff;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
  }
  .dsb {
    font-size: 14px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 80px;
    color: #999999;
  }
  .data-list {
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .biaoji {
        width: 30px;
        height: 30px;
        background-color: #2880ff;
        margin-bottom: 20px;
        align-self: flex-start;
        margin-left:17%;
      }
      img {
        width: 50%;
      }
      p:nth-child(3) {
        font-size: 18px;
        color: #333333;
        margin-bottom: 18px;
      }
      p:nth-child(4) {
        font-size: 14px;
        color: #666666;
        margin-bottom: 25px;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
  width: 100%;
  padding: 40px 0;
  background-color: #fff;
  .title {
   font-size: 18px;
    line-height: 18px;
    color: #333333;
    text-align: center;
  }
  .dsb {
   font-size: 7px;
    text-align: center;
    line-height:20px;
    margin-top: 10px;
    margin-bottom: 50px;
    color: #999999;
  }
  .data-list {
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .biaoji {
        display: none;
        width: 30px;
        height: 30px;
        background-color: #2880ff;
        margin-bottom: 20px;
        align-self: flex-start;
        margin-left:17%;
      }
      img {
        width: 90%;
      }
      p:nth-child(3) {
        margin-top:20px;
        font-size: 9px;
        color: #333333;
        margin-bottom: 10px;
      }
      p:nth-child(4) {
        font-size: 7px;
        color: #666666;
        margin-bottom: 10px;
      }
    }
  }
}
}
</style>
