<template>
  <div>
    <div class="about-info">
      <div class="title">代理收益模式</div>
      <div class="dsb">持续型收入超乎你想象</div>
      <div class="container info-list">
        <div>
          <div class="info-content">
            全国银行卡发卡总张数为87.98亿张，其中信用卡8.01亿张，人均持有信用卡的数量从0.32张上升到0.57张，8000万商户+2亿高端商户人士，需POS机收款或资金周转。
          </div>
          <div class="info-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modeInfo",
  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.about-info {
  padding: 100px 0;
  background-color: #fff;
  .title {
    text-align: center;
    font-size: 36px;
    color: #333333;
  }
  .dsb {
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
    font-size: 14px;
    color: #999999;
  }
  .info-list {
    background-image: url("../../assets/images/Join/Income1.png");
    background-size: 100% 100%;
    display: flex;
    > div {
      width: 41.66%;
      background-color: #fff;
      margin: 80px 0;
      position: relative;
      padding: 75px 40px;
      .info-content {
        text-indent: 2em;
        font-size: 14px;
        line-height: 26px;
        color: #666666;
      }
      .info-line {
        margin-top: 40px;
        width: 48px;
        height: 4px;
        background-color: #2880ff;
        border-radius: 1px;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .about-info {
    padding: 40px 0 30px;
    background-color: #fff;
    .title {
      text-align: center;
      font-size: 18px;
      color: #333333;
    }
    .dsb {
      margin-top: 10px;
      margin-bottom: 30px;
      text-align: center;
      font-size: 7px;
      color: #999999;
    }
    .info-list {
      background-image: url("../../assets/images/Join/Income1.png");
      background-size: cover;
      background-position: center;
      display: flex;
      > div {
        width: 35%;
        background-color:transparent;
        margin:35px 5px 40px;
        padding:0;
        position: relative;
        .info-content {
          text-indent: 2em;
          font-size: 7px;
          line-height: 20px;
          color: #666666;
        }
        .info-line {
          display: none;
        }
      }
    }
  }
}
</style>
