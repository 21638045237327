<template>
  <!-- 代理优势 -->
  <div>
    <div class="supeRiority">
      <p class="title">代理优势</p>
      <ul class="container data-list">
        <li>
          <img :src="Sign" alt="" />
          <p>平台直签</p>
        </li>
        <li>
          <img :src="Money" alt="" />
          <p>公司提供物料<br />及广告市场费用</p>
        </li>
        <li>
          <img :src="Training" alt="" />
          <p>
            公司提供客户售后<br />
            及合伙人日常业务培训
          </p>
        </li>
        <li>
          <img :src="Backstage" alt="" />
          <p>配置独立后台</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Sign from "@/assets/images/profile/Sign.png";
import Money from "@/assets/images/profile/Money.png";
import Training from "@/assets/images/profile/Training.png";
import Backstage from "@/assets/images/profile/Backstage.png";
export default {
  name: "supeRiority",
  data() {
    return {
      Sign,
      Money,
      Training,
      Backstage,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.supeRiority {
  margin-top:60px;
  width: 100%;
  padding: 100px 0;
  background-image: url("../../assets/images/profile/Background.png");
  background-size: 100.1% 100%;
  background-repeat: no-repeat;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 100px;
  }
  .data-list {
    display: flex;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
    }
    p {
      width: 100%;
      height: 80px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.2);
      border: solid 2px #ffffff;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      padding: 0 30px;
    }
    li:nth-child(1) {
      margin-top: 123px;
    }
    li:nth-child(2) {
      margin-top: 82px;
      margin-left: -2px;
    }
    li:nth-child(3) {
      margin-top: 41px;
      margin-left: -2px;
    }
    li:nth-child(4) {
      margin-left: -2px;
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
    margin-top:0px;
    width: 100%;
    padding: 40px 0 30px;
    background-image: url("../../assets/images/profile/Background.png");
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
    .title {
      font-size: 18px;
      line-height: 15px;
      color: #ffffff;
      text-align: center;
      margin-bottom: 10px;
    }
    .data-list {
      display: flex;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
      }
      img {
        width: 40px;
        margin-top:20px;
      }
      p {
        width: 120px;
        height: 35px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.2);
        border: solid 1px #ffffff;
        font-size: 10px;
        line-height: 13px;
        color: #ffffff;
        padding: 0 5px;
      }
      li:nth-child(1) {
        margin-top: 0px;
      }
      li:nth-child(2) {
        margin-top: 0px;
        margin-left: 0px;
      }
      li:nth-child(3) {
        margin-top: 0px;
        margin-left: 0px;
      }
      li:nth-child(4) {
        margin-left: 0px;
      }
    }
  }
}
</style>
