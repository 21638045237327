<template>
  <div>
    <div class="about-info">
      <div class="title">代理收益分析</div>
      <div class="dsb">别人刷卡你赚钱</div>
      <div class="container">
        <ul class="info-list">
          <li class="">
            <ul class="">
              <li>开通1台</li>
              <li>开通10台</li>
              <li>开通50台</li>
              <li>开通100台</li>
              <li>开通300台</li>
              <li>开通500台</li>
              <li>开通1000台</li>
            </ul>
          </li>
          <li class="">
            <ul>
              <li>月刷5万</li>
              <li>月刷50万</li>
              <li>月刷250万</li>
              <li>月刷500万</li>
              <li>月刷1500万</li>
              <li>月刷2500万</li>
              <li>月刷5000万</li>
            </ul>
          </li>
          <li class="">
            <ul>
              <li>月分润30元</li>
              <li>月分润300元</li>
              <li>月分润1500元</li>
              <li>月分润3500元</li>
              <li>月分润12000元</li>
              <li>月分润22500元</li>
              <li>月分润50000元</li>
            </ul>
          </li>
          <li class="">
            <ul>
              <li>年分润360元</li>
              <li>年分润3600元</li>
              <li>年分润18000元</li>
              <li>年分润42000元</li>
              <li>年分润144000元</li>
              <li>年分润270000元</li>
              <li>年分润600000元</li>
            </ul>
          </li>
        </ul>
        <div class="sub-btn">注：市场大数据统计，每台POS机月均刷卡50000元</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "inCome",
  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.about-info {
  padding: 100px 0;
  background-color: #f9f9f9;
  .title {
    text-align: center;
    font-size: 36px;
    color: #333333;
  }
  .dsb {
    margin-top: 40px;
    margin-bottom: 80px;
    text-align: center;
    font-size: 14px;
    color: #999999;
  }
  .sub-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background-color: #2880ff;
    color: #fff;
  }
  .info-list {
    width: 100%;
    display: flex;
    > li {
      width: 25%;
      margin-right:5px;
      &:last-child{
        margin-right:0;
      }
      li {
        height: 48px;
        line-height: 48px;
        text-align: center;
        background-color: #ffffff;
        margin-bottom: 12px;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .about-info {
  padding: 40px 0 30px;
  background-color: #f9f9f9;
  .title {
    text-align: center;
    font-size: 18px;
    color: #333333;
  }
  .dsb {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 7px;
    color: #999999;
  }
  .sub-btn {
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #2880ff;
    color: #fff;
    font-size:8px;
  }
  .info-list {
    width: 100%;
    display: flex;
    > li {
      width: 25%;
      margin-right:5px;
      &:last-child{
        margin-right:0;
      }
      li {
        font-size: 8px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: #ffffff;
        margin-bottom: 7px;
      }
    }
  }
}
}
</style>
