<template>
  <!-- 联创伙伴创业大平台 -->
  <div>
    <div class="supeRiority">
      <p class="title">联创伙伴创业大平台</p>
      <div class="container">
        <div class="logo"><img :src="logo_img" alt="" /></div>
        <div class="data-list">
          <ul class="app-left">
            <li>
              <p>零成本投入</p>
              <img :src="Costs" alt="" />
            </li>
            <li>
              <p>政策公开透明</p>
              <img :src="Policy" alt="" />
            </li>
            <li>
              <p>产品全面多样</p>
              <img :src="Products" alt="" />
            </li>
            <li>
              <p>展业通道宽广</p>
              <img :src="Exhibition" alt="" />
            </li>
          </ul>
          <div class="app-center">
            <img :src="Cellphones" alt="" />
          </div>
          <ul class="app-right">
            <li>
              <img :src="Pipes" alt="" />
              <p>管道收益高</p>
            </li>
            <li>
              <img :src="Profitsharing" alt="" />
              <p>分润系统直发</p>
            </li>
            <li>
              <img :src="Diversity" alt="" />
              <p>收益稳定多元</p>
            </li>
            <li>
              <img :src="Markets" alt="" />
              <p>市场前景广阔</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cellphones from "@/assets/images/profile/Cellphones.png";
import logo_img from "@/assets/images/profile/logo.png";
import Costs from "@/assets/images/Join/Costs.png";
import Policy from "@/assets/images/Join/Policy.png";
import Products from "@/assets/images/Join/Products.png";
import Exhibition from "@/assets/images/Join/Exhibition.png";
import Pipes from "@/assets/images/Join/Pipes.png";
import Profitsharing from "@/assets/images/Join/Profitsharing.png";
import Diversity from "@/assets/images/Join/Diversity.png";
import Markets from "@/assets/images/Join/Markets.png";

export default {
  name: "lchbApp",
  data() {
    return {
      Cellphones,
      logo_img,
      Costs,
      Policy,
      Products,
      Exhibition,
      Pipes,
      Profitsharing,
      Diversity,
      Markets,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0 90px;
  background-color: #fff;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }
  .logo {
    margin-bottom: 40px;
    text-align: center;
    img {
      width: 16.66%;
    }
  }
  .data-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .app-left {
      margin-right: 8.3%;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 60px;
        margin-bottom: 90px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          text-align: right;
        }
        img {
          margin-left: 20px;
        }
      }
    }
    .app-right {
      margin-left: 8.3%;
      li {
        display: flex;
        align-items: center;
        line-height: 60px;
        margin-bottom: 90px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          text-align: left;
        }
        img {
          margin-right: 20px;
        }
      }
    }
    .app-center {
      width: 26.66%;
      text-align: center;
      img {
        width: 100%;
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
    width: 100%;
    padding: 40px 0 30px;
    background-color: #fff;
    .title {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 30px;
    }
    .logo {
      display: none;
      margin-bottom: 40px;
      text-align: center;
      img {
        width: 16.66%;
      }
    }
    .data-list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .app-left {
        margin-right:5%;
        li {
          display: flex;
          align-items: center;
           line-height: 30px;
          margin-bottom: 45px;
          font-size: 9px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            text-align: right;
          }
          img {
            margin-left: 5px;
            width:10px;
          }
        }
      }
      .app-right {
        margin-left: 5%;
        li {
          display: flex;
          align-items: center;
          line-height: 30px;
          margin-bottom: 45px;
           font-size: 9px;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            
            text-align: left;
          }
          img {
            margin-right: 5px;
            width:10px;
          }
        }
      }
      .app-center {
        width: 130px;
        text-align: center;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
