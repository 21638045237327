<template>
  <!-- 联系我们 -->
  <div>
    <div class="supeRiority">
      <p class="title">代理加盟</p>
      <div class="container">
        <ul class="data-list">
          <li class="app-right">
            <div class="uer-name"><input type="text" placeholder="姓名" /></div>
            <div class="uer-name"><input type="text" placeholder="电话" /></div>
            <div>
              <v-distpicker
                @province="onChangeProv"
                @city="onChangeCity"
                @area="onChangeArea"
                :placeholders="placeholders"
              ></v-distpicker>
            </div>
            <div class="sub-btn">提交</div>
          </li>
          <li class="app-left">
            <div class="kefu">
              <p>在线客服</p>
              <p>工作时间 9:00-21:00，您可以通过电话联系我们</p>
            </div>
            <div class="open-phone">
              <div>
                <img :src="Telephone" alt="" />
                <p>客服热线</p>
              </div>
              <p>4008090573</p>
            </div>
            <div class="open-phone">
              <div>
                <img :src="Address" alt="" />
                <p>总部地址</p>
              </div>
              <p>浙江省嘉兴市经济技术开发区中环南路2608号福地广场</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import VDistpicker from "@/components/vDistpicker/Distpicker";
import Address from "@/assets/images/profile/Address.png";
import Telephone from "@/assets/images/profile/Telephone.png";
export default {
  name: "joinUs",
  components: { VDistpicker },
  data() {
    return {
      Address,
      Telephone,
      placeholders: {
        province: "选择省",
        city: "选择市",
        area: "选择区",
      },
    };
  },
  mounted() {},

  methods: {
    onChangeProv(e) {
      console.log("省", e);
    },
    onChangeCity(e) {
      console.log("市", e);
    },
    onChangeArea(e) {
      console.log("县", e);
    },
  },
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0;
  background-color: #fff;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }
  .data-list {
    display: flex;
    .app-left {
      width: 42%;
      padding-right: 50px;
      .kefu {
        margin-bottom: 60px;
        p:nth-child(1) {
          font-size: 24px;
          color: #333333;
          margin-bottom: 15px;
        }
        p:nth-child(2) {
          font-size: 16px;
          color: #999999;
        }
      }
      .open-phone {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #666666;
        margin-bottom: 40px;
        img {
          width: 50px;
          display: block;
          margin-right: 20px;
        }
        > div {
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }
        > P {
          margin-left: 25px;
          flex: 1;
          line-height: 26px;
        }
      }
    }
    .app-right {
      width: 50%;
      margin-right: 8%;
      .sub-btn {
        margin-top: 20px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: #2880ff;
        border-radius: 30px;
        font-size: 24px;
        color: #ffffff;
        &:hover{
            background-color: #055fe0;
        }
      }
      .uer-name {
        margin-bottom: 20px;
        input {
          text-align: center;
          outline: none;
          width: 100%;
          height: 60px;
          line-height: 60px;
          background-color: #ffffff;
          border-radius: 20px;
          border: solid 1px #cccccc;
          font-size: 20px;
        }
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .app-left {
    display: none;
  }
  .supeRiority {
    padding: 40px 0;
    .title {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .data-list {
      padding: 0px 15px;
      .app-right {
        width: 100%;
        margin-right: 0;
        .sub-btn {
          margin-top: 20px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: #2880ff;
          border-radius: 15px;
          font-size: 12px;
          color: #ffffff;
        }
        .uer-name {
          margin-bottom: 20px;
          input {
            text-align: center;
            outline: none;
            width: 100%;
            height: 30px;
            line-height: 30px;
            background-color: #ffffff;
            border-radius: 10px;
            border: solid 1px #cccccc;
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
<style>
body {
  background-color: #fff;
}
</style>