<template>
  <!-- 应用下载 -->
  <div>
    <div class="supeRiority">
      <p class="title">应用下载</p>
      <div class="container">
        <ul class="data-list">
          <li class="app-left">
            <img :src="Cellphones" alt="" />
            <div class="download" @click.stop="toConfirm()">
              下载联创伙伴APP
            </div>
          </li>
          <li class="app-right">
            <img :src="logo_img" class="logo" alt="" />
            <div class="app-down">
              <div class="dl-app">
                <img :src="Android" alt="" />
                <img :src="QRcodeAndroid" alt="" />
              </div>
              <div class="dl-app">
                <img :src="AppStore" alt="" />
                <img :src="QRcodeIos" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Cellphones from "@/assets/images/profile/Cellphones.png";
import logo_img from "@/assets/images/profile/logo.png";
import Android from "@/assets/images/profile/Android.png";
import AppStore from "@/assets/images/profile/AppStore.png";
import QRcodeAndroid from "@/assets/images/profile/QRcode-Android.png";
import QRcodeIos from "@/assets/images/profile/QRcode-ios.png";
export default {
  name: "downLoad",
  data() {
    return {
      Cellphones,
      logo_img,
      Android,
      AppStore,
      QRcodeAndroid,
      QRcodeIos,
    };
  },
  mounted() {},

  methods: {
    // 跳转下载链接
    toConfirm() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log(isIOS);
      if (isAndroid) {
        location.href = encodeURI(
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.jxsdpay.lchb"
        );
      } else if (isIOS) {
        location.href = encodeURI(
          "https://apps.apple.com/cn/app/%E8%81%94%E5%88%9B%E4%BC%99%E4%BC%B4/id1576155621"
        );
      } else {
        location.href = encodeURI(
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.jxsdpay.lchb"
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0 90px;
  background-color: #fff;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }
  .data-list {
    display: flex;
    .app-left {
      width: 23%;
      margin-left: 8%;
      margin-right: 9%;
      img {
        width: 100%;
      }
      .download {
        display: none;
      }
    }
    .app-right {
      width: 60%;
      .logo {
        width: 35%;
        margin-top: 100px;
        margin-bottom: 115px;
      }
      .app-down {
        width: 100%;
        display: flex;
        .dl-app {
          width: 22.22%;
          margin-right: 30px;
          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
    width: 100%;
    padding: 40px 0 30px;
    background-color: #fff;
    .title {
      font-size: 18px;
      line-height: 38px;
      color: #333333;
      text-align: center;
      margin-bottom: 30px;
    }
    .data-list {
      display: flex;
      padding: 0px;
      .app-left {
        width: 100px;
        margin: 0 auto;
        img {
          width: 100%;
        }
        .download {
          display: block;
          margin-top: 20px;
          width: 100px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          font-size: 8px;
          color: #fff;
          background-color: #2880ff;
        }
      }
      .app-right {
        display: none;
        width: 60%;
        .logo {
          width: 35%;
          margin-top: 100px;
          margin-bottom: 115px;
        }
        .app-down {
          width: 100%;
          display: flex;
          .dl-app {
            width: 22.22%;
            margin-right: 30px;
            img {
              width: 100%;
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
body {
  background-color: #fff !important;
}
</style>