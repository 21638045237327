<template>
  <div>
    <section class="section" style="padding-bottom: 0">
      <div class="container-list">
        <swiper :options="swiperOptions">
          <swiper-slide v-for="(item, index) in slideList" :key="index">
            <div class="">
              <img :src="item.detail" alt="" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
      </div>
      <apply />
    </section>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import apply from "@/components/experience/apply";

import "swiper/css/swiper.css";
export default {
  name: "Experience",
  components: {
    swiper,
    swiperSlide,
    apply,
  },
  data() {
    return {
      height: "100%",
      swiperOptions: {
        loop: true, //无限循环
        autoplay: {
          //自动切换
          delay: 3000,
          stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
          disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
          type: "bullets",
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next", //若改变右箭头样式，需改变名字，默认为.swiper-button-next
          prevEl: ".swiper-button-prev", //若改变左箭头样式，需改变名字，默认为.swiper-button-prev
        },
      },
      //数据列表
      slideList: [
        {
          detail: require("@/assets/images/banner/banner7.jpg"),
        },
        {
          detail: require("@/assets/images/banner/banner8.jpg"),
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
  },
};
</script>

<style lang="less" scoped>
.container-list {
  //设置相对定位，用于定位左右箭头
  position: relative;
  width: 100%;
  //设置轮播图整体样式
  .swiper-container {
    width: 100%;
    img {
      width: 100%;
    }
  }
  //设置右箭头样式
  .swiper-button-next {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 40px;
    cursor: pointer;
    &:after {
      font-size: 14px;
      color: #fff;
      font-weight: 600;
    }
  }
  //设置左箭头样式
  .swiper-button-prev {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 40px;
    cursor: pointer;
    &:after {
      font-size: 14px;
      color: #fff;
      font-weight: 600;
    }
  }
  //设置分页样式
  .swiper-pagination {
    bottom: 20px;
    text-align: center;
    width: 100%;
    ::v-deep .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 4px;
      margin: 0 3px;
      opacity: 1;
    }
    ::v-deep .swiper-pagination-bullet-active {
      width: 30px;
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .container-list {
    //设置相对定位，用于定位左右箭头
    position: relative;
    width: 100%;
    //设置轮播图整体样式
    .swiper-container {
      width: 100%;
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
    }
    //设置右箭头样式
    .swiper-button-next {
      display: none;
    }
    //设置左箭头样式
    .swiper-button-prev {
      display: none;
    }
    //设置分页样式
    .swiper-pagination {
      bottom: 10px;
      text-align: center;
      width: 100%;
      ::v-deep .swiper-pagination-bullet {
        width: 4px;
        height: 4px;
        background-color: #fff;
        border-radius: 4px;
        margin: 0 3px;
        opacity: 1;
      }
      ::v-deep .swiper-pagination-bullet-active {
        width: 15px;
      }
    }
  }
}
</style>
