import Vue from 'vue'
import App from '@/App.vue'
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import BaiduMap from 'vue-baidu-map'
 
Vue.use( BaiduMap, { ak : 'WLiWxXcGAWoOPHiFHFlWeaRfaPefrOWz' } )
// 引入css样式
import '@/plugins/bootstrapvue'                  // 引入 bootstrap-vue
import 'zico/css/zico.min.css'                   // zico 图标库 css
import '@/assets/css/animate.css'
import '@/assets/css/style.css'

// 挂载 axios
Vue.prototype.$http = axios

Vue.config.productionTip = false
// 配置 Ajax 请求根地址
axios.defaults.baseURL = 'http://127.0.0.1:8000/api'





new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
