<template>
  <!-- 联系我们 -->
  <div>
    <div class="supeRiority">
      <p class="title">免费在线申领中</p>
      <p class="dsb">留下您的申领信息，客服会联系您，当天为您发货</p>
      <div class="container">
        <ul class="data-list">
          <li class="app-right">
            <div class="uer-name"><input type="text" placeholder="姓名" /></div>
            <div class="uer-name"><input type="text" placeholder="电话" /></div>
             <div class="uer-name"><input type="text" placeholder="微信" /></div>
            <div class="mb-20">
              <v-distpicker
                @province="onChangeProv"
                @city="onChangeCity"
                @area="onChangeArea"
                :placeholders="placeholders"
              ></v-distpicker>
            </div>
             <div class="uer-name"><input type="text" placeholder="详细地址" /></div>
              <div class="uer-name"><input type="text" placeholder="留言(例如：我想领取合利宝)" /></div>
            <div class="sub-btn">提交</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import VDistpicker from "@/components/vDistpicker/Distpicker";
import Address from "@/assets/images/profile/Address.png";
import Telephone from "@/assets/images/profile/Telephone.png";
export default {
  name: "apply",
  components: { VDistpicker },
  data() {
    return {
      Address,
      Telephone,
      placeholders: {
        province: "选择省",
        city: "选择市",
        area: "选择区",
      },
    };
  },
  mounted() {},

  methods: {
    onChangeProv(e) {
      console.log("省", e);
    },
    onChangeCity(e) {
      console.log("市", e);
    },
    onChangeArea(e) {
      console.log("县", e);
    },
  },
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0;
  background-color: #fff;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 40px;
  }
  .dsb {
    font-size: 14px;
    text-align: center;
    color: #999999;
    margin-bottom: 80px;
  }
  .data-list {
    display: flex;
   justify-content: center;
    .app-right {
      width: 57.5%;
      .sub-btn {
        margin-top: 20px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background-color: #2880ff;
        border-radius: 30px;
        font-size: 24px;
        color: #ffffff;
        &:hover{
            background-color: #055fe0;
        }
      }
      .uer-name {
        margin-bottom: 20px;
        input {
          text-align: center;
          outline: none;
          width: 100%;
          height: 60px;
          line-height: 60px;
          background-color: #ffffff;
          border-radius: 20px;
          border: solid 1px #cccccc;
          font-size: 20px;
        }
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
  width: 100%;
  padding: 40px 0 30px;
  background-color: #fff;
  .title {
    font-size: 18px;
    line-height: 18px;
    color: #333333;
    text-align: center;
    margin-bottom: 10px;
  }
  .dsb {
    font-size: 7px;
    text-align: center;
    color: #999999;
    margin-bottom: 30px;
  }
  .data-list {
    display: flex;
   justify-content: center;
    .app-right {
      width: 80%;
      .sub-btn {
        margin-top: 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #2880ff;
        border-radius: 15px;
        font-size: 10px;
        color: #ffffff;
        &:hover{
            background-color: #055fe0;
        }
      }
      .uer-name {
        margin-bottom: 20px;
        input {
          text-align: center;
          outline: none;
          width: 100%;
          height: 30px;
          line-height: 30px;
          background-color: #ffffff;
          border-radius: 10px;
          border: solid 1px #cccccc;
          font-size: 10px;
        }
      }
    }
  }
}
}
</style>

