<template>
  <div id="app" @click="clickRoot">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    clickRoot(){
       this.$store.commit("clickRootNumAction", 1);
    }
  }
}
</script>

<style lang="less" scoped>
</style>
