import Vue from 'vue'
import Router from 'vue-router'
import { BasicLayout } from '@/layouts';

import Home from '../pages/Home.vue'
import About from '../pages/About.vue'
import Join from '../pages/Join.vue'
import Experience from '../pages/Experience.vue'
import AnaLysis from '../pages/anaLysis.vue'
import AnaLysisDetail1 from '../components/analysis/AnaLysisDetail1.vue'
import AnaLysisDetail2 from '../components/analysis/AnaLysisDetail2.vue'
import AnaLysisDetail3 from '../components/analysis/AnaLysisDetail3.vue'
import AnaLysisDetail4 from '../components/analysis/AnaLysisDetail4.vue'
import AnaLysisDetail5 from '../components/analysis/AnaLysisDetail5.vue'
import AnaLysisDetail6 from '../components/analysis/AnaLysisDetail6.vue'
import App from '../pages/App.vue'
import ApplicationAgent from '../pages/ApplicationAgent.vue'
import ContactUs from '../pages/ContactUs.vue'
Vue.use(Router)

export default new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes:[
        {
            path: '/',
            component: BasicLayout,
            redirect: '/home',
            children: [
                {
                path: '/home',
                name: 'home',
                component: Home
            },
            {
                path: '/about',
                name: 'about',
                component: About
            },
            {
                path: '/join',
                name: 'join',
                component: Join
            },
            {
                path: '/anaLysis',
                name: 'anaLysis',
                component: AnaLysis
            },
            {
                path: '/experience',
                name: 'experience',
                component: Experience
            },
            {
                path: '/app',
                name: 'app',
                component: App
            },
            {
                path: '/applicationAgent',
                name: 'applicationAgent',
                component: ApplicationAgent
            },
            {
                path: '/contactUs',
                name: 'contactUs',
                component: ContactUs
            },
            {
                path: '/anaLysis-detail1',
                name: 'anaLysis-detail1',
                component: AnaLysisDetail1
            },
            {
                path: '/anaLysis-detail2',
                name: 'anaLysis-detail2',
                component: AnaLysisDetail2
            },
            {
                path: '/anaLysis-detail3',
                name: 'anaLysis-detail3',
                component: AnaLysisDetail3
            },
            {
                path: '/anaLysis-detail4',
                name: 'anaLysis-detail4',
                component: AnaLysisDetail4
            },
            {
                path: '/anaLysis-detail5',
                name: 'anaLysis-detail5',
                component: AnaLysisDetail5
            },
            {
                path: '/anaLysis-detail6',
                name: 'anaLysis-detail6',
                component: AnaLysisDetail6
            },
        ]
        }
    ]
})