<template>
  <div>
    <div class="about-info">
      <div class="container info-list">
        <div>{{ title }}</div>
        <div>{{ dsb }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "crumbs",
  props: {
    title: {
      type: String,
      default: "关于我们",
    },
    dsb: {
      type: String,
      default: "首页 > 关于我们 > 企业简介",
    },
  },
  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.about-info {
  width: 100%;
  background-image: url("../../assets/images/Bottomcolumn.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .info-list {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div:nth-child(1) {
      font-size: 36px;
      color: #ffffff;
    }
    > div:nth-child(2) {
      font-size: 14px;
      color: #cccccc;
    }
  }
}
@media (max-width: 750px) {
  .about-info {
    display: none;
  }
}
</style>
