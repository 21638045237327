<template>
  <div>
    <div class="about-info">
      <div class="container info-list">
        <div>
          <img class="cover-info" :src="Print" alt="" />
          <p class="info-title">联创伙伴</p>
          <div class="info-content">
            联创伙伴平台是提供创新技术、市场运营、多品牌产品、技能培训、金融服务的一体化创业系统提供商。
          </div>
          <div class="info-content">
            它整合多品牌收款工具拓展终端市场，打造了一个产品安全可靠、分润及时发放、管理轻松便捷，收益稳健增长可信赖的综合性服务展业管理平台，构建合作共赢的开放生态圈，解决了支付创业难题，提升展业效率，为众多创业者实现梦想创造机会。
          </div>
          <div class="info-line"></div>
        </div>
        <b-img
          class="Machines"
          :src="Machines"
          fluid
          alt="Responsive image"
        ></b-img>
      </div>
    </div>
  </div>
</template>

<script>
import Print from "@/assets/images/profile/Print.png";
import Machines from "@/assets/images/profile/Machines.png";
export default {
  name: "aboutInfo",
  data() {
    return {
      Print,
      Machines,
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.about-info {
  margin: 61px 0;
  background-color: #f9f9f9;
  .info-list {
    display: flex;
    > div {
      width: 50%;
      background-color: #fff;
      position: relative;
      padding: 110px 62px 50px 53px;
      .cover-info {
        position: absolute;
        top: 0;
        left: 0;
        width: 260px;
        z-index: 1;
      }
      .info-title {
        position: relative;
        z-index: 2;
        font-size: 36px;
        line-height: 30px;
        color: #333333;
        margin-bottom: 80px;
      }
      .info-content {
        text-indent: 2em;
        font-size: 14px;
        line-height: 26px;
        color: #666666;
      }
      .info-line {
        margin-top: 40px;
        width: 48px;
        height: 4px;
        background-color: #2880ff;
        border-radius: 1px;
      }
    }
    .Machines {
      width: 50%;
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .about-info {
    margin: 0;
    background-color: #fff;
    .info-list {
      display: flex;
      > div {
        width: 100%;
        background-color: #fff;
        position: relative;
        padding: 40px 0 30px;
        .cover-info {
          position: absolute;
          top: 0;
          left: 0;
          width: 130px;
          z-index: 1;
        }
        .info-title {
          position: relative;
          z-index: 2;
          font-size: 18px;
          line-height: 15px;
          color: #333333;
          text-align: center;
          margin-top: 40px;
          margin-bottom: 30px;
        }
        .info-content {
          text-indent: 2em;
          font-size: 7px;
          line-height: 20px;
          color: #666666;
        }
        .info-line {
          display: none;
        }
      }
      .Machines {
        display: none;
      }
    }
  }
}
</style>
