<template>
  <!-- 软件优势 -->
  <div>
    <div class="supeRiority">
      <p class="title">行业分析</p>
      <div class="container">
        <ul class="data-list">
          <li class="">
            <div @click="goDetail(1)">
              <p>如何正确选择个人POS终端产品</p>
              <p>2023-03-22 10:00:00</p>
              <p>
                如何选择好的POS产品，来有针对性的合理用卡，8个维度好坏有标准。
              </p>
            </div>
            <div class="img-right" @click="goDetail(1)">
              <img :src="Enter" alt="" />
            </div>
          </li>
          <li class="">
            <div @click="goDetail(2)">
              <p>为什么选择做POS机代理?做POS机代理选择联创伙伴平台的好处!</p>
              <p>2023-03-22 10:00:00</p>
              <p>
                我国信用卡的发行量是非常大的，相信POS机代理在未来几年还是非常火爆的，做支付行业创业选择POS机代理相信你的收入一定很可观。
              </p>
            </div>
            <div class="img-right" @click="goDetail(2)">
              <img :src="Enter" alt="" />
            </div>
          </li>
          <li class="">
            <!-- 3 -->
            <div @click="goDetail(3)">
              <p>怎么成为联创伙伴创业平台pos机代理商</p>
              <p>2023-02-22 10:00:00</p>
              <p>想做支付行业POS机代理的，联创伙伴是你的支付创业首选。</p>
            </div>
             <div class="img-right" @click="goDetail(3)">
              <img :src="Enter" alt="" />
            </div>
          </li>
          <li class="">
            <!-- 4 -->
            <div @click="goDetail(4)">
              <p>适合做支付创业的4类人，联创伙伴期待您的加入</p>
              <p>2023-02-22 10:00:00</p>
              <p>
                　支付行业赚钱这是毋庸置疑的，支付创业既可以做主干，也可以做副业。
              </p>
            </div>
            <div class="img-right" @click="goDetail(4)">
              <img :src="Enter" alt="" />
            </div>
          </li>
          <li class="">
            <!-- 5 -->
            <div @click="goDetail(5)">
              <p>新手怎么进入支付行业，做支付创业的新手要注意什么?</p>
              <p>2023-01-22 10:00:00</p>
              <p>
                总的来说做支付创业是选择大于努力，今天和大伙分享一下新手怎么进入支付行业，做支付创业的新手要注意什么?
              </p>
            </div>
             <div class="img-right" @click="goDetail(5)">
              <img :src="Enter" alt="" />
            </div>
          </li>
          <li class="">
            <!-- 6 -->
            <div @click="goDetail(6)">
              <p>POS机代理能看到客户刷卡吗?POS机代理的分润是多少?</p>
              <p>2023-01-22 10:00:00</p>
              <p>
                POS机代理都可以看到商户的刷卡记录的，因为POS机代理是靠商户刷卡交易来赚取分润的。
              </p>
            </div>
            <div class="img-right" @click="goDetail(6)">
              <img :src="Enter" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Enter from "@/assets/images/analyse/Enter.png";
export default {
  name: "analysis",
  data() {
    return {
      Enter,
    };
  },
  methods: {
    goDetail(value) {
      this.$router.push({ name: `anaLysis-detail${value}`});
    },
  },
};
</script>

<style lang="less" scoped>
.supeRiority {
  width: 100%;
  padding: 100px 0;
  background-color: #f9f9f9;
  .title {
    font-size: 36px;
    line-height: 30px;
    color: #333333;
    text-align: center;
    margin-bottom: 80px;
  }
  .data-list {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      margin-bottom: 40px;
      padding: 40px;
      > div {
        p:nth-child(1) {
          line-height: 30px;
          margin-bottom: 12px;
          font-size: 24px;
          color: #333333;
        }
        p:nth-child(2) {
          line-height: 13px;
          font-size: 14px;
          color: #999999;
          margin-bottom: 15px;
        }
        p:nth-child(3) {
          line-height: 15px;
          font-size: 16px;
          color: #666666;
        }
      }
      .img-right {
        img {
          width: 30px;
        }
      }
    }
  }
}
/* 手机端样式 */
@media (max-width: 750px) {
  .supeRiority {
    width: 100%;
    padding: 40px 0 10px;
    background-color: #f9f9f9;
    .title {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      text-align: center;
      margin-bottom: 30px;
    }
    .data-list {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        margin-bottom: 40px;
        padding: 15px 10px;
        > div {
          width: 100%;
          p:nth-child(1) {
            width: 80%;
            line-height: 1;
            margin-bottom: 8px;
            font-size: 12px;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          p:nth-child(2) {
            line-height: 1;
            font-size: 8px;
            color: #999999;
            margin-bottom: 8px;
          }
          p:nth-child(3) {
            line-height: 1;
            font-size: 10px;
            color: #666666;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .img-right {
          display: none;
          img {
            width: 30px;
          }
        }
      }
    }
  }
}
</style>
