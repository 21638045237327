<template>
  <div class="anaLysis">
    <section class="section" style="padding-bottom: 0">
      <crumbs title="联系我们" dsb="首页 > 联系我们" />
       <contactUs/>
    </section>
  </div>
</template>

<script>
import crumbs from "@/components/about/crumbs";
import contactUs from "@/components/home/contactUs";

export default {
  name: "anaLysis",
  components: {
    crumbs,
    contactUs,
  },
  data() {
    return {
    };
  },
  
  mounted() {},
  methods: {
  },
};
</script>

<style scoped>

</style>